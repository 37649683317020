import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ExporterPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.exporterRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.exporterExporter,
    );
    this.importAutocomplete = permissionChecker.match(
      Permissions.values.exporterAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.exporterCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.exporterEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.exporterDestroy,
    );
  }
}
